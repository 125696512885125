import React from "react"
import TagsCore from "./tags-core"

type Props = {
  data: {
    allPost: {
      group: {
        fieldValue: string
        totalCount: number
      }[]
    }
  }
  [key: string]: any
}

export default function Tags({ ...props }: Props) {
  const {
    data: { allPost },
  } = props

  return <TagsCore list={allPost.group} {...props} />
}
